import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import Container from '../components/Container';
import LinkWrapper from '../components/LinkWrapper';
import Button from '../components/Button';
import { Headline, Highlight } from '../components/Typography';

import { colors } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';

/** Angepasster Link */
const StyledLink = styled(LinkWrapper)`
    color: ${colors.primary};
`;

/** Styles für Inputs */
const inputStyle = css`
    font-size: ${rem(16)};
    padding: 0.75rem 1rem;
    box-shadow: none;
    border: 1px solid ${colors.gray300};
    width: 100%;
    margin-bottom: 1rem;
`;

/** Input */
const Input = styled.input`
    ${inputStyle};
`;

/** Textarea */
const Textarea = styled.textarea`
    ${inputStyle};
    min-height: ${rem(150)};
`;

/** Hidden Helper für Honeypot-Field */
const Hidden = styled.div`
    display: none;
`;

const MandatoryHint = styled.small`
    display: block;
`;

/** Textarea */
const Form = styled.form`
    ${mq.smallOnly`
        margin-top: ${rem(30)} !important;
    `};
`;

const ContactPage = ({ data }) => {
    const { stageImage } = data;
    return (
        <Layout title="Kontakt" description="Meta News">
            <Stage image={stageImage.childImageSharp} title="Kontakt" />
            <Section>
                <Container grid cols="2">
                    <div>
                        <Headline level="h2">
                            Sie haben Fragen oder Interesse an einer Zusammenarbeit? Dann melden Sie
                            sich bei uns!
                        </Headline>
                        <address>
                            <Highlight textColor="black">Schütz Dienstleistungen</Highlight>
                            <br />
                            Reinigung & Wartung
                            <br />
                            Schönbuchstrasse 19/1
                            <br />
                            72631 Aichtal-Neuenhaus
                            <br />
                            {/* eslint-disable-next-line */}
                            Tel: <StyledLink to="tel: 07127 / 50311"> 07127 / 50311</StyledLink>
                            <br />
                            Fax: 07127 / 969155
                            <br />
                            <StyledLink to="mailto:info@schuetzdienstleistungen.de">
                                info@schuetzdienstleistungen.de
                            </StyledLink>
                        </address>
                    </div>
                    <Form
                        data-netlify="true"
                        name="Kontaktformular"
                        method="POST"
                        action="/danke"
                        netlify-honeypot="bot-field"
                    >
                        <input type="hidden" name="form-name" value="Kontaktformular" />
                        <Input type="text" placeholder="Vorname, Name *" name="Name" required />
                        <Input type="tel" placeholder="Telefon *" name="Telefon" />
                        <Input type="email" placeholder="E-Mail *" name="E-Mail" required />
                        <Textarea placeholder="Ihre Mitteilung" name="Mitteilung" required />
                        <Hidden>
                            <label htmlFor="bot-field">
                                Füllen Sie dieses Feld nicht aus:
                                <input name="bot-field" />
                            </label>
                        </Hidden>
                        <Button type="submit" gap="l">
                            Absenden
                        </Button>
                        <MandatoryHint>* Pflichtfeld</MandatoryHint>
                    </Form>
                </Container>
            </Section>
        </Layout>
    );
};

ContactPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "contact/stage-image.png" }) {
            ...StageFluid
        }
    }
`;

export default ContactPage;
